import axios from '@/axios'
import { setAuthTokens } from 'axios-jwt'

const baseURL = process.env.VUE_APP_BASE_URL

// ---------------------------------------------------- Общие
export function login(data) {
  return new Promise((resolve, reject) => {
    axios
      .post('jwt/token/', data)
      .then(response => {
        if (response.data.access) {
          setAuthTokens({
            accessToken: response.data.access,
            refreshToken: response.data.refresh
          })
        }
        resolve(response)
      })
      .catch(error => reject(error))
  })
}

export function getUser() {
  return axios.get('me/')
}

export function patchUser(data) {
  return axios.patch('me/', data)
}

export function getGroups() {
  return axios.get('groups/')
}

// ---------------------------------------------------- Словари
export function getCitizenship() {
  return axios.get('dicts/citizenship/?active=true')
}

export function getParticipationStatus() {
  return axios.get('dicts/status/participant/?active=true')
}

export function getMealType() {
  return axios.get('dicts/food/?active=true')
}

export function getGender() {
  return axios.get('dicts/gender/?active=true')
}

export function getLegalRepresentative() {
  return axios.get('dicts/legalrepresentative/?active=true')
}

export function getClothingSize() {
  return axios.get('dicts/clothing/?active=true')
}

export function getOccupation() {
  return axios.get('dicts/occupation/?active=true')
}

export function getDelegations() {
  return axios.get('dicts/delegation/')
}

export function getStatus() {
  return axios.get('dicts/status/?active=true')
}

export function getCountry() {
  return axios.get('dicts/country/?active=true')
}

export function checkDelegationHash(hash) {
  return axios.get(`dicts/delegation/check/${hash}`)
}

export function getDelegationSettings() {
  return axios.get('dicts/delegation/settings/')
}

export function patchDelegationSettings(data) {
  return axios.patch('dicts/delegation/settings/', data)
}

export function getParticipationCost() {
  return axios.get('dicts/participationcost/?active=true')
}

export function getPaymentMethod() {
  return axios.get('dicts/paymentmethod/?active=true')
}

export function getDirectoratesCategories() {
  return axios.get('dicts/directorates/?active=true')
}

export function getDirectoratesServices(directorate__id) {
  return axios.get(`dicts/directorates/services/?active=true&directorate__id=${directorate__id}`)
}

export function getRegions() {
  return axios.get('dicts/region/')
}

export function getSecurityCategories() {
  return axios.get('dicts/securitycategories/?active=true')
}

export function getTransferType() {
  return axios.get('dicts/transfer/')
}

export function getSmiTypes() {
  return axios.get('dicts/smi/?active=true')
}

export function checkSecurityCategoriesHash(hash) {
  return axios.get(`dicts/securitycategories/check/${hash}/`)
}

export function getAwardsDict() {
  return axios.get('dicts/awards/')
}

// ---------------------------------------------------- Организации

export function getCommonOrganizations(query) {
  return axios.get(`common/org/?page=${query.page}&page_size=${query.page_size}&query=${query.search}`)
}

export function getCommonOrganization(id) {
  return axios.get(`common/org/${id}/`)
}

export function postCommonOrganization(form) {
  return axios.post('common/org/', form)
}

export function putCommonOrganization(query) {
  return axios.put(`common/org/${query.id}/`, query.form)
}

export function deleteCommonOrganization(id) {
  return axios.delete(`common/org/${id}/`)
}

// ---------------------------------------------------- ФМС

export function getCommonFMSList(query) {
  return axios.get(`common/fms/?page=${query.page}&page_size=${query.page_size}&query=${query.search}`)
}

export function getCommonFMS(id) {
  return axios.get(`common/fms/${id}/`)
}

export function postCommonFMS(form) {
  return axios.post('common/fms/', form)
}

export function putCommonFMS(query) {
  return axios.put(`common/fms/${query.id}/`, query.form)
}

export function deleteCommonFMS(id) {
  return axios.delete(`common/fms/${id}/`)
}

// ---------------------------------------------------- Пользователи CRM

export function getCRMUsers(query) {
  return axios.get(`users/?page=${query.page}&page_size=${query.page_size}&search=${query.search}`)
}

export function getCRMUser(id) {
  return axios.get(`users/${id}/`)
}

export function postCRMUser(form) {
  return axios.post('users/', form)
}

export function putCRMUser(id, form) {
  return axios.put(`users/${id}/`, form)
}

export function deleteCRMUser(id) {
  return axios.delete(`users/${id}/`)
}

// ---------------------------------------------------- DaData

export function getFMS(query) {
  return axios.get(`search/fms/?q=${query}`)
}

export function getEduOrg(query) {
  return axios.get(`search/edu-org/?q=${query}`)
}

export function getOrg(query) {
  return axios.get(`search/org/?q=${query}`)
}

export function getFullOrg(query) {
  return axios.get(`search/full-org/?q=${query}`)
}

export function getAddress(query) {
  return axios.get(`search/address/?q=${query}`)
}

export function getBank(query) {
  return axios.get(`search/bank/?q=${query}&full_data=true`)
}

// ---------------------------------------------------- Files
export function postFile(data) {
  return axios.post('files/', data)
}

export function getFile(url) {
  return axios.get(url, { responseType: 'blob' })
}

// ---------------------------------------------------- Участники

export function getMembersList(params) {
  const url = new URL(`${baseURL}participants/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

export function getMember(id) {
  return axios.get(`participants/${id}/`)
}

export function postMember(data) {
  return axios.post('participants/', data)
}

export function putMember(id, data) {
  return axios.put(`participants/${id}/`, data)
}

export function postMemberFromHash(data) {
  return axios.post('participants/delegation/add/', data)
}

export function getMemberStat(params) {
  const url = new URL(`${baseURL}statistic/participant/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

export function getMembersExport() {
  return axios.get('export/delegation/participants/?pdf=true', { responseType: 'blob' })
}

export function deleteMember(id) {
  return axios.delete(`participants/${id}/`)
}

// ---------------------------------------------------- Номера

export function getNumbersList() {
  return axios.get('numbers/')
}

export function getNumber(id) {
  return axios.get(`numbers/${id}/`)
}

export function postNumber(data) {
  return axios.post('numbers/', data)
}

export function putNumber(id, data) {
  return axios.put(`numbers/${id}/`, data)
}

export function getDirections() {
  return axios.get('numbers/directions/')
}

export function getNominations(direction) {
  return axios.get(`numbers/nominations/?direction=${direction}`)
}

export function getSubNominations(nomination) {
  return axios.get(`numbers/subnominations/?nomination=${nomination}`)
}

export function getAmount(direction, nomination, subnomination = '') {
  return axios.get(`numbers/amounts/?direction=${direction}&nomination=${nomination}&subnomination=${subnomination}`)
}

export function getNumberStat(params) {
  const url = new URL(`${baseURL}statistic/numbers/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

export function deleteNumber(id) {
  return axios.delete(`numbers/${id}/`)
}

export function getNumbersApp() {
  return axios.get('export/delegation/numbers/?pdf=true', { responseType: 'blob' })
}

export function getNumbersZip() {
  return axios.get('export/delegation/numbers-all/?pdf=true', { responseType: 'blob' })
}

export function getAwardsExcel() {
  return axios.get('export/awards/excel/', { responseType: 'blob' })
}

export function getAwardsProtocol(direction) {
  return axios.get(`export/awards/?Direction=${direction}&pdf=false`, { responseType: 'blob' })
}

export function getBadgeParticipant(id) {
  return axios.get(`export/single-badges/participants/?id=${id}`, { responseType: 'blob' })
}

export function getBadgeDirectorate(id) {
  return axios.get(`export/single-badges/management/?id=${id}`, { responseType: 'blob' })
}

// ---------------------------------------------------- Договоры
export function getContractsLegal(page, page_size) {
  return axios.get(`contracts/legal/?page=${page}&page_size=${page_size}`)
}

export function getContractLegal(id) {
  return axios.get(`contracts/legal/${id}/`)
}

export function postContractLegal(data) {
  return axios.post('contracts/legal/', data)
}

export function putContractLegal(id, data) {
  return axios.put(`contracts/legal/${id}/`, data)
}

export function deleteContractLegal(id) {
  return axios.delete(`contracts/legal/${id}/`)
}

export function getContractsIndividual(page, page_size) {
  return axios.get(`contracts/individual/?page=${page}&page_size=${page_size}`)
}

export function getContractIndividual(id) {
  return axios.get(`contracts/individual/${id}/`)
}

export function postContractIndividual(data) {
  return axios.post('contracts/individual/', data)
}

export function putContractIndividual(id, data) {
  return axios.put(`contracts/individual/${id}/`, data)
}

export function deleteContractIndividual(id) {
  return axios.delete(`contracts/individual/${id}/`)
}

export function getContractIndividualExport(contract_id, pdf, seal) {
  return axios.get(`export/contracts/individual/${contract_id}/?pdf=${pdf}&seal=${seal}`, { responseType: 'blob' })
}

export function getContractLegalExport(contract_id, pdf, seal) {
  return axios.get(`export/contracts/legal/${contract_id}/?pdf=${pdf}&seal=${seal}`, { responseType: 'blob' })
}

export function getContractStat(delegation_name) {
  return axios.get(`statistic/contracts/?delegation_name=${delegation_name}`)
}

// ---------------------------------------------------- Письма-вызовы
export function getCallLetterList(page, page_size) {
  return axios.get(`letters/call-letters/?page=${page}&page_size=${page_size}`)
}

export function postCallLetter(data) {
  return axios.post('letters/call-letters/', data)
}

export function getCallLetter(id) {
  return axios.get(`letters/call-letters/${id}/`)
}

export function putCallLetter(id, data) {
  return axios.put(`letters/call-letters/${id}/`, data)
}

export function deleteCallLetter(id) {
  return axios.delete(`letters/call-letters/${id}/`)
}

export function getCallLetterExport(letter_id) {
  return axios.get(`export/letters/call/${letter_id}/?pdf=true`, { responseType: 'blob' })
}

// ---------------------------------------------------- Дирекции
export function checkDirectorateHash(directorate_hash, security_category_hash) {
  return axios.get(`dicts/directorates/check/${directorate_hash}/${security_category_hash}/`)
}

export function postDirectorateFromHash(data) {
  return axios.post('servicelist/management/public/add/', data)
}

export function postDirectorate(data) {
  return axios.post('servicelist/management/', data)
}

export function getDirectorate(id) {
  return axios.get(`servicelist/management/${id}/`)
}

export function putDirectorate(id, data) {
  return axios.put(`servicelist/management/${id}/`, data)
}

export function deleteService(id) {
  return axios.delete(`servicelist/management/${id}/`)
}

export function getDirectoratesList(params) {
  const url = new URL(`${baseURL}servicelist/management/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

// ---------------------------------------------------- Трансферы
export function getTransferList(params) {
  const url = new URL(`${baseURL}transfers/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value || value === false) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

export function postTransfer(data) {
  return axios.post('transfers/', data)
}

export function getTransfer(id) {
  return axios.get(`transfers/${id}/`)
}

export function putTransfer(id, data) {
  return axios.put(`transfers/${id}/`, data)
}

export function deleteTransfer(id) {
  return axios.delete(`transfers/${id}/`)
}

// ---------------------------------------------------- СМИ
export function getSmiList(page, page_size) {
  return axios.get(`servicelist/smi/?page=${page}&page_size=${page_size}`)
}

export function postSmi(data) {
  return axios.post('servicelist/smi/', data)
}

export function getSmi(id) {
  return axios.get(`servicelist/smi/${id}/`)
}

export function putSmi(id, data) {
  return axios.put(`servicelist/smi/${id}/`, data)
}

export function deleteSmi(id) {
  return axios.delete(`servicelist/smi/${id}/`)
}

// ---------------------------------------------------- Экспорты Excel/Zip
export function getParticipantsExport() {
  return axios.get('export/participants/', { responseType: 'blob' })
}

export function getNumbersExport() {
  return axios.get('export/numbers/', { responseType: 'blob' })
}

export function getTransfersExport() {
  return axios.get('export/transfers/', { responseType: 'blob' })
}

export function getContractsExport() {
  return axios.get('export/contracts/', { responseType: 'blob' })
}

export function getManagementsExport() {
  return axios.get('export/managements/', { responseType: 'blob' })
}

export function getSmiExport() {
  return axios.get('export/smi/', { responseType: 'blob' })
}

export function getRealizationReportExport() {
  return axios.get('export/realizationreport/export/', { responseType: 'blob' })
}

export function getRealizationReportFilesExport() {
  return axios.get('export/realizationreport/files/export/', { responseType: 'blob' })
}

// ---------------------------------------------------- Эксперты
export function getExpertsList(page, page_size, search) {
  return axios.get(`servicelist/experts/?page=${page}&page_size=${page_size}&search=${search}`)
}

export function postExpert(data) {
  return axios.post('servicelist/experts/', data)
}

export function getExpert(id) {
  return axios.get(`servicelist/experts/${id}/`)
}

export function putExpert(id, data) {
  return axios.put(`servicelist/experts/${id}/`, data)
}

export function deleteExpert(id) {
  return axios.delete(`servicelist/experts/${id}/`)
}

// ---------------------------------------------------- Программа

export function getProgramInterval(params) {
  const url = new URL(`${baseURL}program/interval/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value || value === false) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

export function getAllProgram(time, direction) {
  return axios.get(`program/program/${time}/${direction}/`)
}

export function patchAllProgram(id, data) {
  return axios.patch(`program/program/${id}/`, data)
}

export function getGraduateList(direction, time) {
  return axios.get(`program/program/${time}/${direction}/scoresheet/`)
}

export function putGraduateList(number, expert, data) {
  return axios.put(`program/scorenumber/${number}/${expert}/`, data)
}

export function getProgramExcel() {
  return axios.get('export/program/', { responseType: 'blob' })
}

export function getProgramZipPdf() {
  return axios.get('export/program/zip/?pdf=true', { responseType: 'blob' })
}

export function getProgramZipWord() {
  return axios.get('export/program/zip/?pdf=false', { responseType: 'blob' })
}

export function getProgramNumbersZip() {
  return axios.get('export/program/numbers/zip/?pdf=true', { responseType: 'blob' })
}

export function getProgramScoresheetsZip(pdf) {
  return axios.get(`export/program/scoresheets/zip/?pdf=${pdf}`, { responseType: 'blob' })
}

export function getProgramScoresheetsExcel() {
  return axios.get('export/program/numbers/scoresheet/excel/', { responseType: 'blob' })
}

export function getProgramAwards(params) {
  const url = new URL(`${baseURL}program/awards/sheet/`)
  Object.entries(params).forEach(([key, value]) => {
    if (value || value === false) url.searchParams.append(key, value)
  })
  return axios.get(url)
}

export function postAward(number, data) {
  return axios.post(`program/awards/${number}/add/`, data)
}

// ---------------------------------------------------- Realizationreport
export function getRealizationReport() {
  return axios.get('export/realizationreport/detail/')
}

export function postRealizationReport(data) {
  return axios.post('export/realizationreport/create/', data)
}

export function patchRealizationReport(data) {
  return axios.patch('export/realizationreport/update/', data)
}

export function getRealizationReportStat() {
  return axios.get('export/realizationreport/stats/')
}

// ---------------------------------------------------- Благодарственные письма
export function getThankNoteList(page, page_size, type) {
  return axios.get(`letters/thanks-letters/?page=${page}&page_size=${page_size}&type=${type}`)
}

export function postThankNote(data) {
  return axios.post('letters/thanks-letters/', data)
}

export function getThankNote(id) {
  return axios.get(`letters/thanks-letters/${id}/`)
}

export function putThankNote(id, data) {
  return axios.put(`letters/thanks-letters/${id}/`, data)
}

export function deleteThankNote(id) {
  return axios.delete(`letters/thanks-letters/${id}/`)
}

export function getThankNoteExport(id) {
  return axios.get(`export/single-letter/${id}/`, { responseType: 'blob' })
}

export function getThankNotesExport() {
  return axios.get('export/letters/thanks/delegation/', { responseType: 'blob' })
}
export function getCertificatesParticipantsExport() {
  return axios.get('export/diploms/participants/new/', { responseType: 'blob' })
}

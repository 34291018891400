<template>
  <div
    class="sidebar"
    :class="{ 'sidebar--mini': $store.state.sidebar, 'sidebar--open': $store.state.sidebarOpen && sidebarOpen}"
    @mouseenter="showMenu = true"
    @mouseleave="showMenu = false"
  >
    <div class="sidebar__top">
      <router-link :to="{ name: '' }" class="sidebar__logo">
        <img src="@/assets/img/sidebar-logo.svg" alt="logo">
        <img src="@/assets/img/single-logo.svg" alt="main logo" />
      </router-link>
      <button
        class="sidebar__toggler"
        :class="{ 'is-hidden': $store.state.sidebar }"
        type="button"
        @click="toggleSidebar"
      ></button>
      <button
        @click="sidebarMobileClose"
        class="sidebar__close"
      >
      </button>
    </div>
    <div class="sidebar__user">
      <div class="user" :class="{ 'user--hide': $store.state.sidebar && !showMenu }">
        <div class="user__info">
          <div class="user__wrap">
            <span class="user__name">{{ user.first_name }} {{ user.last_name }}</span>
            <app-cells position="start" :indent="false">
              <div class="user__id"><b>ID:</b>{{ user.id }}</div>
            </app-cells>
          </div>
        </div>
        <button @click="onLogout" class="user__logout">
          <img src="@/assets/img/logout.svg" alt="Logout">
        </button>
      </div>
    </div>
    <div class="sidebar__bottom">
      <Menu :show="showMenu || $store.state.sidebarOpen" :mobileShow="$store.state.sidebarOpen" :userAdmin="isAdmin" />
      <div class="sidebar__line"></div>
      <div class="sidebar__block">
        <template>
          <div class="sidebar__title">Контакты</div>
          <div class="sidebar__links">
            <a :href="link.url" class="sidebar__link" v-for="link in links" :key="link.id">
              <img :src="require('@/assets/img/sidebar/' + link.icon)" :alt="link.icon">
              <span>{{ link.text }}</span>
            </a>
          </div>
        </template>
<!--        <div class="sidebar__line sidebar__line&#45;&#45;100"></div>-->
<!--        <div class="sidebar__text">-->
<!--          {{ // app_name === 'oovo' ? 'СУМ РСВ ООВО' : 'СУМ РСВ ПОО' }}-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { clearAuthTokens } from 'axios-jwt'
import { getUser } from '@/http'
import Menu from './AppMenu'

export default {
  name: 'AppSidebar',
  components: {
    Menu
  },
  data () {
    return {
      links: [
        { id: 1, text: '+7 (999) 811-15-00', url: 'tel:+79539138223', icon: 'phone-icon.svg' },
        /*{ id: 2, text: 'antonov_group', url: 'tg://resolve?domain=antonov_group', icon: 'tg-blue.svg' },
        { id: 3, text: 'support@antonov.group', url: 'mailto:support@antonov.group', icon: 'link-icon.svg' },*/
        { id: 4, text: 'ПН-ПТ 10:00 - 18:00 (Москва)', url: '', icon: 'time-blue.svg' },
      ],
      showMenu: false,
      wWidth: window.innerWidth <= 1100,
      user: {},
      show_menu: false,
      isAdmin: false,
      app_name: process.env.VUE_APP_FESTIVAL_NAME,
    }
  },
  computed: {
    // is_staff() {
    //   return this.$store.state.user.permissions && this.$store.state.user.permissions.every(item => item !== 'can_abiturient')
    // },
    // is_admin() {
    //   return this.$store.state.user.permissions && this.$store.state.user.permissions.some(item => item === 'can_admin')
    // },
    sidebarOpen() {
      return this.wWidth <= 1100
    },
    // user() {
    //   return this.$store.state.user || {}
    // },
    // sysInfo() {
    //   return this.$store.state.sysInfo
    // },
    // isAdminInAbi() {
    //   return localStorage.getItem('accessToken_admin')
    // }
  },
  created() {
    getUser()
      .then(response => {
        this.user = response.data
        this.show_menu = true
        if (!this.$store.state.delegation) {
          this.$store.commit('setDelegation', response.data.delegation)
          this.$store.commit('setDelegationHash', response.data.delegation_hash)
        }
        if (this.user.groups.length) {
          if (this.user.groups.some(group => group.code === 'admin')) {
            this.isAdmin = true
            if (!this.$store.state.user_role) this.$store.commit('setUserRole', 'admin')
          } else if (this.user.groups.some(group => group.code === 'delegation_head')) {
            if (!this.$store.state.user_role) this.$store.commit('setUserRole', 'head')
          } else if (this.user.groups.some(group => group.code === 'staff')) {
            if (!this.$store.state.user_role) this.$store.commit('setUserRole', 'staff')
          }
        }
      })
  },
  methods: {
    toggleSidebar () {
      this.$store.commit('sidebarToggle')
    },
    sidebarMobileClose() {
      if (this.$store.state.sidebarOpen) this.$store.commit('sidebarMobileToggle')
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    onLogout() {
      clearAuthTokens()
      localStorage.clear()
      this.$router.push({ name: 'login' })
    },
    // onGetAdminToken() {
    //   localStorage.setItem('accessToken', localStorage.getItem('accessToken_admin'))
    //   localStorage.setItem('refreshToken', localStorage.getItem('refreshToken_admin'))
    //   localStorage.removeItem('accessToken_admin')
    //   localStorage.removeItem('refreshToken_admin')
    //   location.assign(`/enrollee/${localStorage.getItem('currentAbiturient')}/profile`)
    //   localStorage.removeItem('currentAbiturient')
    // }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style lang="sass">
.sidebar
  flex-shrink: 0
  position: sticky
  top: 0
  left: 0
  z-index: 99
  width: 300px
  height: 100vh
  background-color: #f8fdff
  box-shadow: 0 4px 14px rgba(172, 196, 206, 0.4)
  transition: width 0.3s, transform 0.3s

  +max-w($laptop_sm)
    transform: translateX(-100%)

.sidebar--mini:not(.sidebar--open)
  width: 92px

  .sidebar__logo
    margin-left: 0
    text-align: center

    img
      &:first-child
        display: none
        max-width: none

      &:last-child
        display: block
        //width: 45px
        margin: 0 auto

  .sidebar__block
    display: none

  .sidebar__line
    display: none

  //.user__logout
  //  display: none

  .user__link
    display: none

  &:hover
    .sidebar__logo
      //margin-left: 25px

      img
        &:first-child
          display: block

        &:last-child
          display: none

    .sidebar__block
      display: block

    .sidebar__line
      display: block

    .user__logout
      display: block

    .user__link
      display: block

  .sidebar__toggler
    display: none

  &:hover
    width: 300px

    .sidebar__toggler
      display: block

    .sidebar__bottom
      overflow-y: auto

  .sidebar__bottom
    overflow-y: hidden

.sidebar--open
  transform: translateX(0)

  &:before
    visibility: visible
    opacity: 1
    transition: opacity 0.3s 0.1s

.sidebar__logo
  position: relative
  display: block
  //margin-left: 25px
  padding: 30px 0
  line-height: 0
  overflow: hidden
  user-select: none

  img
    //&:first-child
    //  width: 200px

    &:last-child
      display: none

.sidebar__top
  position: relative
  border-bottom: 1px solid #E7EEF4

.sidebar__toggler
  position: absolute
  right: 17px
  top: 50%
  z-index: 1
  display: flex
  align-items: center
  justify-content: center
  width: 16px
  height: 16px
  border-radius: 50%
  border: 1px solid $color-theme
  transform: translateY(-50%)

  &::after
    content: ""
    width: 6px
    height: 6px
    border-radius: 50%
    background-color: $color-theme

  &.is-hidden::after
    content: ""

  +max-w($laptop_sm)
    display: none

.sidebar__part
  margin: 22px 0 20px

.sidebar__bottom
  height: calc(100vh - 180px)
  padding-bottom: 20px
  overflow-x: hidden
  overflow-y: scroll
  scrollbar-color: $color-theme
  scrollbar-width: thin

  &::-webkit-scrollbar
    width: 2px

  &::-webkit-scrollbar-track
    border-radius: 5px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: $color-theme

.sidebar__line
  width: 80%
  height: 1px
  margin: 10px auto 24px
  background-color: $color-border

.sidebar__line--100
  width: 100%

.sidebar__block
  padding: 0 34px

.sidebar__title
  margin-bottom: 15px
  font-size: 16px
  font-weight: 600

.sidebar__links
  margin-bottom: 22px

.sidebar__link
  display: flex
  align-items: flex-start
  padding: 11px 0
  color: $color-text-base
  text-decoration: none
  transition: color 0.3s

  img
    flex-shrink: 0
    margin-right: 18px

  span
    font-size: 16px

  &[href]:hover
    color: $color-theme

.sidebar__text
  margin-bottom: 25px
  font-size: 16px

.sidebar__close
  position: absolute
  right: 17px
  top: 50%
  z-index: 1
  width: 25px
  height: 25px
  padding: 2px
  cursor: pointer
  transform: translateY(-60%)

  &:before,
  &:after
    content: ''
    position: absolute
    top: 50%
    left: 2px
    width: 20px
    height: 2px
    border-radius: 1px
    background-color: $color-theme

  &:before
    transform: rotate(45deg)

  &:after
    transform: rotate(-45deg)

  +min-w($laptop_sm)
    display: none

.sidebar__user
  position: relative
  z-index: 5
  border-bottom: 1px solid #E7EEF4
  box-shadow: -15px 8px 11px #f8fdff

.user__link
  color: $color-theme
  font-size: 12px
  transition: opacity 0.3s

  &:hover
    opacity: 0.7
</style>
